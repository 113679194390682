import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "src/utils/GatsbyLink"

const ArchivePostItem = props => {
    if (props.tags.includes(props.type)) {
      return (
        <Link
          to={props.data.download_file ? `${props.data.download_file.url}` : ""}
          target="_blank"
          className="group col-span-1 text-left border-l border-gold mb-12"
          title={`${props.data.title.text}`}
        >
          <Fade>
            <div className="bg-white w-full relative -ml-px">
              <Img
                fluid={
                  props.data.featured_image ? props.data.featured_image.fluid : ""
                }
                alt={props.data.featured_image.alt}
                className="absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out group-hover:opacity-70 h-full"
              />
            </div>
            <div className="p-6 pb-0">
              <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-4 group-hover:text-gold duration-500 ease-in-out">
                {props.data.title.text}
              </h2>
              <div className="description text-sm">
                <RichText
                  render={props.data.description.richText}
                  serializeHyperlink={GatsbyLink}
                />
              </div>
              <i className="fal text-xl mt-6 text-gold group-hover:text-darkgrey duration-500 ease-in-out fa-arrow-to-bottom"></i>
            </div>
          </Fade>
        </Link>
      )
    } else if (props.type === "all") {
      return (
        <Link
          to={props.data.download_file ? `${props.data.download_file.url}` : ""}
          target="_blank"
          className="group col-span-1 text-left border-l border-gold mb-12"
          title={`${props.data.title.text}`}
        >
          <Fade>
            <div className="bg-white w-full relative -ml-px">
              <Img
                fluid={
                  props.data.featured_image ? props.data.featured_image.fluid : ""
                }
                alt={props.data.featured_image.alt}
                className="absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out group-hover:opacity-70 h-full"
              />
            </div>
            <div className="p-6 pb-0">
              <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-4 group-hover:text-gold duration-500 ease-in-out">
                {props.data.title.text}
              </h2>
              <div className="description text-sm">
                <RichText
                  render={props.data.description.richText}
                  serializeHyperlink={GatsbyLink}
                />
              </div>
              <i className="fal text-xl mt-6 text-gold group-hover:text-darkgrey duration-500 ease-in-out fa-arrow-to-bottom"></i>
            </div>
          </Fade>
        </Link>
      )
    }
    return ""
  }
  
  const ArchivePostsContainer = props => {
    return (
      <div className="blog-items">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {props.data.props.map((post, index) => {
            return (
              <ArchivePostItem {...post.node} type={props.type} key={index} />
            )
          })}
        </div>
      </div>
    )
  }

  const BrochureDownloadsListing = props => {
    // Switch tabs when you click
    const [toggled, setToggle] = useState("All")
    function handleTabClick(props) {
        setToggle(props)
    }
    
    return (
        <Fade>
        <div className="w-full bg-white py-6">
            <div className="py-0 md:px-0 px-6 overflow-x-auto">
            <div className="space-x-4 w-max m-auto">
                <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                    toggled === "All" ? "active" : ""
                }`}
                onClick={() => handleTabClick("All")}
                >
                All Brochures
                </button>
                <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                    toggled === "Internal" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Internal")}
                >
                Internal Doors
                </button>
                <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                    toggled === "Front" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Front")}
                >
                Front Doors
                </button>
                <button
                className={`btn font-bold text-xxs whitespace-nowrap ${
                    toggled === "Garage" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Garage")}
                >
                Garage Doors
                </button>
            </div>
            </div>
        </div>
        <section className="bg-offwhite overflow-hidden downloads">
            <div className="container">
            <div
                className={`${toggled === "All" ? "visible" : "invisible h-0"}`}
            >
                <div
                className={`transition-opacity duration-1000 ${
                    toggled === "All" ? "opacity-100" : "opacity-0"
                }`}
                >
                <ArchivePostsContainer data={props} type={"all"} />
                </div>
            </div>
            <div
                className={`${
                toggled === "Internal" ? "visible" : "invisible h-0"
                }`}
            >
                <div
                className={`transition-opacity duration-1000 ${
                    toggled === "Internal" ? "opacity-100" : "opacity-0"
                }`}
                >
                <ArchivePostsContainer data={props} type={"Internal Doors"} />
                </div>
            </div>
            <div
                className={`${toggled === "Front" ? "visible" : "invisible h-0"}`}
            >
                <div
                className={`transition-opacity duration-1000 ${
                    toggled === "Front" ? "opacity-100" : "opacity-0"
                }`}
                >
                <ArchivePostsContainer data={props} type={"Front Doors"} />
                </div>
            </div>
            <div
                className={`${
                toggled === "Garage" ? "visible" : "invisible h-0"
                }`}
            >
                <div
                className={`transition-opacity duration-1000 ${
                    toggled === "Garage" ? "opacity-100" : "opacity-0"
                }`}
                >
                <ArchivePostsContainer data={props} type={"Garage Doors"} />
                </div>
            </div>
            </div>
        </section>
        </Fade>
    )
    }

const ThankYou = props => {

    const crumbs = [
        { label: "Brochure Downloads", href: "/brochure-downloads", active: true },
    ]

  return (
    <article>
      <Seo title={"Thank You | Brochure Downloads"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <section className={`bg-offwhite`}>
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              Brochure Downloads
            </h2>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-2xl mt-6 m-auto">
              Download one of our detailed brochures to find out more about our
              front, internal and garage door ranges. Featuring our most popular
              designs along with technical specifications, configuration
              information, our range of woods and finishes, and available
              accessories, you can pick your perfect new door from the comfort
              of your own home.
            </p>
          </Fade>
        </div>
      </section>
      <div>
          <BrochureDownloadsListing
            props={props.data.allPrismicBrochure.edges}
          />
      </div>
      <section className="block-three-columns bg-verylightgrey">
        <div className="container grid grid-cols-1 lg:grid-cols-3 gap-16">
          <Fade>
            <Link className="group" title="Made in the UK" to="/contact/">
              <img
                src="https://images.prismic.io/deuren/c49a2d18-46d6-4c51-9453-1ad8452b480d_brochure-download-related-get-in-touch.jpg?auto=compress,format"
                className="transition duration-500 ease-in-out group-hover:opacity-70 inline mb-10"
                alt="Brochure Download Related Get in Touch"
              />
              <h1 className="font-display text-gold rfs:text-4xl">Get in Touch</h1>
              <div className="w-10 mt-3 separator"></div>
              <div className="mt-6 leading-normal">
                <p>
                  Whether it’s to book an appointment to visit us, get expert
                  advice or talk through your requirements, we’d love to hear
                  from you.
                </p>
              </div>
            </Link>
          </Fade>
          <Fade>
            <Link className="group" title="Made in the UK" to="/visit-the-factory/">
              <img
                src="https://images.prismic.io/deuren/51803698-4364-4c37-97e0-b9a0b4709cb1_brochure-download-related-visit-the-factory.jpg?auto=compress,format"
                className="transition duration-500 ease-in-out group-hover:opacity-70 inline mb-10"
                alt="Brochure Download Related Visit the Factory"
              />
              <h1 className="font-display text-gold rfs:text-4xl">
                Visit the Factory
              </h1>
              <div className="w-10 mt-3 separator"></div>
              <div className="mt-6 leading-normal">
                <p>
                  It’s true when they say seeing is believing. Get exclusive
                  access to our premises and see our expert engineers in action.
                </p>
              </div>
            </Link>
          </Fade>
          <Fade>
            <Link className="group" title="Made in the UK" to="/request-a-quote/">
              <img
                src="https://images.prismic.io/deuren/0b684aba-b6c3-40bb-bf50-775cc36576a7_brochure-download-related-request-a-quote.jpg?auto=compress,format"
                className="transition duration-500 ease-in-out group-hover:opacity-70 inline mb-10"
                alt="Brochure Download Related Request a Quote"
              />
              <h1 className="font-display text-gold rfs:text-4xl">
                Request a Quote
              </h1>
              <div className="w-10 mt-3 separator"></div>
              <div className="mt-6 leading-normal">
                <p>
                  From handcrafted interiors doors to precision made, striking
                  front doors, it all starts with us finding out your exact
                  requirements.
                </p>
              </div>
            </Link>
          </Fade>
        </div>
      </section>
    </article>
  )
}

export default ThankYou

export const ArchiveBrochureDownloadsQueryThankYou = graphql`
  query ArchiveBrochureDownloadsQueryThankYou {
    allPrismicBrochure {
      edges {
        node {
          data {
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            download_file {
              url
            }
            description {
              richText
            }
          }
          tags
        }
      }
    }
  }
`